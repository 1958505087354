/**
 *
 * Linkedcamp
 * Author: Linkedcamp
 * Email: support@linkedcamp.com
 * Website: linkedcamp.com
 * Version: 1.0
 * Date: Tue Sep 28 2021
 * Category: Pages
 * Title: Header
 *
 */

import React, { useEffect, useState } from 'react';
import { Button, Container, Tooltip } from 'reactstrap';

// Icons
import LoginIcon from 'icons/LoginIcon';

// styles
import './header.css';

const Header = ({ drawerShow, setDrawerShow }) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      let mainHeader = document.getElementsByClassName("main-header");
      mainHeader = mainHeader[0] ? mainHeader[0] : null;
      if (!mainHeader) return;

      if (window.pageYOffset > 50) mainHeader.classList.add("fixed");
      else mainHeader.classList.remove("fixed");
    }
  });

  return (
    <header className="main-header">
      <Container>
        <div className="nav-holder">
          <div className="logo">
            <a href="/" rel="nooppener noreferrer">
              <img src="https://d2h9jghou9rbg3.cloudfront.net/v2/logo.png" alt="LINKEDCAMP" width="170px" />
            </a>
          </div>
          <ul className="main-menu">
            <SolutionsMenu />
            <li>
              <a href="/features" rel="nooppener noreferrer">Features</a>
            </li>
            <li>
              <a href="/pricing" rel="nooppener noreferrer">Pricing</a>
            </li>
            <li>
              <a href="/agency" rel="nooppener noreferrer">Agency</a>
            </li>
            {/* <PartnersMenu /> */}
            {/* <li>
              <a href="https://linkedcamp.com/blog" rel="nooppener noreferrer">Blog</a>
            </li> */}
            {/* <li>
              <a href="/faqs" rel="nooppener noreferrer">FAQ</a>
            </li> */}
            <ResourcesMenu />
            <li></li>
            <li className="mobile-active">
              <a className="drawer-icon" onClick={() => setDrawerShow(!drawerShow)}>
                <svg height="24" width="24" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24">
                  <line x1="3" x2="21" y1="12" y2="12"/><line x1="3" x2="21" y1="6" y2="6"/><line x1="3" x2="21" y1="18" y2="18"/>
                </svg>
              </a>
            </li>
            <li>
              <Button color="secondary" onClick={() => window.open('/discovery')}>Book a Call</Button>
            </li>
            <li>
              <Button color="primary" onClick={() => window.open("https://app.linkedcamp.com/register", "_blank")}>
                <span className="link-text">14 Days Free Trial</span>
                <svg className="link-icon" height="24" width="24">
                  <circle cx="15" cy="8" r="4"/>
                  <path d="M15,14c-6.1,0-8,4-8,4v2h16v-2C23,18,21.1,14,15,14z"/>
                  <line fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="2" x1="5" x2="5" y1="7" y2="15"/>
                  <line fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="2" x1="9" x2="1" y1="11" y2="11"/>
                </svg>
              </Button>
            </li>
            <li>
              <a id="login-tooltip" href="https://app.linkedcamp.com">
                <LoginIcon className={"link-icon"} color={"#007eff"} />
              </a>
              <Tooltip
                toggle={() => setTooltipOpen(!tooltipOpen)}
                isOpen={tooltipOpen}
                target={"login-tooltip"}
              >
                Click here to login
              </Tooltip>
            </li>
          </ul>
        </div>
      </Container>
    </header>
  );
}

Header.propTypes = {};

export default Header;

const SolutionsMenu = () => (
  <li className="menu-dropdown-item">
    <span>Solutions <img src="/images/arrow.png" alt="" className="dropdown-icon" /></span>
    <div className="dropdown-content">
      <div className="dropdown-content-wrap">
        <div className="dropdown-content-item">
          <a href="/linkedin-outreach" className="title">
            LinkedIn Outreach
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Improve your LinkedIn outreach and generate hot leads.</p>
        </div>
        <div className="dropdown-content-item">
          <a href="/image-gif-personalization" className="title">
            Image gif personalization
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Enhance your engagement rates with our Image and GIF personalization.</p>
        </div>
      </div>
      <div className="dropdown-content-wrap">
        <div className="dropdown-content-item">
          <a href="/smart-sequences" className="title">
            Smart Sequences
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Better connect with your prospects and turn them into lasting customers.</p>
        </div>
        <div className="dropdown-content-item">
          <a href="/email-outreach" className="title">
            Email Outreach
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Boost your email outreach efforts and generate 3x higher results.</p>
        </div>
      </div>
      <div className="dropdown-content-wrap">
        <figure className="img-wrap">
          <img src="/images/logo-rocket.png" alt="" />
        </figure>
      </div>
    </div>
  </li>
);

const PartnersMenu = () => (
  <li className="menu-dropdown-item partners-item">
    <span>Partners <img src="/images/arrow.png" alt="" className="dropdown-icon" /></span>
    <div className="dropdown-content">
      <div className="dropdown-content-wrap">
        <div className="dropdown-content-item">
          <a href="/agency" className="title">
            Agencies
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Our multi-channel solutions help your agency grow</p>
        </div>
        <div className="dropdown-content-item">
          <a href="/affiliates" className="title">
            Affiliates
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Gain massive revenue by becoming our affiliate</p>
        </div>
      </div>
    </div>
  </li>
);

const ResourcesMenu = () => (
  <li className="menu-dropdown-item">
    <span>Resources <img src="/images/arrow.png" alt="" className="dropdown-icon" /></span>
    <div className="dropdown-content resources">
      <div className="dropdown-content-wrap">
      <div className="dropdown-content-item">
          <a href="/success-stories" className="title">
            Success Stories
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Clients accelerate their lead generation, save time and close more deals.</p>
        </div>
        <div className="dropdown-content-item">
          <a href="https://linkedcamp.com/blog" target="_blank" className="title">
            Blog
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Offering insight, tips, tricks and vision to accelerate your lead generation.</p>
        </div>
      </div>
      <div className="dropdown-content-wrap">
        <div className="dropdown-content-item">
          <a href="https://help.linkedcamp.com" target="_blank" className="title">
            Knowledge Base
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Better understanding of how different features in Linkedcamp works.</p>
        </div>
        <div className="dropdown-content-item">
          <a href="/faqs" className="title">
            FAQ's
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Some common questions and we have them answered.</p>
        </div>
      </div>
    </div>
  </li>
);
